import { render, staticRenderFns } from "./events.vue?vue&type=template&id=6c2beb86&scoped=true&"
import script from "./events.vue?vue&type=script&lang=js&"
export * from "./events.vue?vue&type=script&lang=js&"
import style0 from "./events.vue?vue&type=style&index=0&id=6c2beb86&lang=scss&scoped=true&"
import style1 from "./events.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./events.vue?vue&type=style&index=2&id=6c2beb86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c2beb86",
  null
  
)

export default component.exports